import axios from "axios";
import { nanoid } from "nanoid";
import React, { useEffect, useState } from "react";

function App() {

    const [name, setName] = useState("");
    const [result, setResult] = useState("");
  
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setName(e.target.value);
    };
  
    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const form = e.currentTarget;
        axios({
            method: e.currentTarget.method,
            url: e.currentTarget.action,
            data: form,
            headers: { "Content-Type": "multipart/form-data" }
        })
        .then(function (response) {
            console.log(response);
            setResult(response.data);
        })
        .catch(function (error) {
            console.log(error);
        });
    };
  
    return (
        <div className="App">
            <form
                action="/server.php"
                method="post"
                onSubmit={(event) => handleSubmit(event)}
            >
                <label htmlFor="name">Name: </label>
                <input
                    type="text"
                    id="name"
                    name="name"
                    value={name}
                    onChange={(event) => handleChange(event)}
                />
                <br />
                <button type="submit">Submit</button>
            </form>
            <h1>{result}</h1>
        </div>
    );
}

export default App;